.modal-msg {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.603);
    z-index: 2;

    .body {
        position: fixed;
        color: black;
        width: 30vw;
        height: 300px;
        padding: 1% 1%;
        background-color: white;
        border-radius: 12px;
    }

    .close {
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
        background-color: transparent;
        border: 0;
        float: right;
        font-size: 1.35rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: none;
        opacity: 0.5;
    }

    .content-buttons {
        text-align: center;
        height: 50%;

        .btn {
            margin: 0 10px;
        }
    }
    .content-title {
        text-transform: uppercase;
        font-weight: bold;
        color: #140A48;
    }
    .content-text {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 22px;
        color: #818084;
    }

    .logo{
        width: 135px;
        height: auto
    }


    .btn-yes {
        color: #fff;
        background-color: #727cf5;
        border-color: #727cf5;

        &:hover {
            color: #fff;
            background-color: #4e5bf2;
            border-color: #4250f2;
        }
    }

    .btn-no {
        color: #313a46;
        background-color: #eef2f7;
        border-color: #eef2f7;

        &:hover {
            color: #313a46;
            background-color: #d4deeb;
            border-color: #cbd7e7;
        }
    }


}

