//
// dropzone.scss
//

.dropzone {
    border: 2px dashed #b9c0cb;
    background: #f9f9f9;
    border-radius: 6px;
    cursor: pointer;
    outline: none;
    min-height: 150px;

    .dz-message {
        text-align: center;
        padding: 40px;
        outline: none !important;
    }
    &.dz-started {
        .dz-message {
            display: none;
        }
    }
}

.dropzone-previews {
    .avatar-sm {
        height: 130px;
        width: 100%;
    }

    .rounded {
        border-radius: 0.25rem!important;
    }

    .bg-light {
        background-color: #eef2f7!important;
    }

    img, svg {
        vertical-align: middle;
    }
    img {
        border-style: none;
    }

    .col-auto {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .align-items-center {
        -webkit-align-items: center!important;
        align-items: center!important;
    }

    .p-2 {
        padding: 0.75rem!important;
    }

    .border {
        border: 1px solid #dee2e6!important;
    }
}