.msg-wrapper {
    display: inline-block;
    position: relative;
}

.msg-popup {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-90%);
    padding: 6px;
    color: #0acf97;
    background: #fff;
    border: 1px solid #0acf97;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1.5;
    z-index: 100;
    white-space: nowrap;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.msg-popup::before {
    content: " ";
    left: 90%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1);
}

.msg-popup.top {
    top: calc(72px * -1);
}

.msg-popup.top::before {
    top: 100%;
    border-top-color: #0acf97;
}

.msg-popup.right {
    left: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

.msg-popup.right::before {
    left: calc(6px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: #bdfabd;
}

.msg-popup.bottom {
    bottom: calc(30px * -1);
}

.msg-popup.bottom::before {
    bottom: 100%;
    border-bottom-color: #bdfabd;
}

.msg-popup.left {
    left: auto;
    right: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

.msg-popup.left::before {
    left: auto;
    right: calc(6px * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: #bdfabd;
}
