.active-tab{
    background-color: rgba(122, 113, 137, 0.15) !important;
    border: 2px solid #5200E5 !important; 
    color: white !important
}
.testHover{
    position: absolute;

        border: 1px solid #483964;
        border-radius: 8px;
        width: 380px;
        /* height: 100p; */
        top: -80px;
        z-index: 22;
        font-size: 11px !important;
        padding: 5% !important;
        background-color: white;
        opacity: 0.90;
        font-weight: 800;
        overflow: hidden
}
.search-bar{
    border: 1px solid rgb(204, 203, 203);
    height: 38px;
    width: 59vw;
    border-radius: 4px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.979) !important;
    padding-left: 2%;
}
.nft-card:hover{
    border: 1px solid rgba(80, 0, 229, 0.24);
    cursor: pointer !important
}
.search-input{
    height: 36px !important;
    border: none !important;
    background-color: rgba(255, 255, 255, 0.979) !important;
    width: 100%;
    font-weight: 600;
    padding-left: 5px
}
.fit-iframe{
    -webkit-transform:scale(1);
    -moz-transform-scale: scale(1);
}
.search-input:focus{
    border: none !important;
    outline: none !important;
    /* background-color: white !important; */
}
.form-check-input:before{
    display: none !important
}
.form-check-input:checked{
    /* background-image: none !important; */
    background-color: #5200E5 !important
}
.rare{
    background-color: #73DDFF !important;
}
.special{
    background-color: #73A3FF !important;
}
.uncommon{
    background-color: #B8FFD0 !important;
}
.epic{
    background-color: #9E00FF !important;
}
.legendary{
    background-color: #FF8A00 !important;
}
.property-box{
    min-width: 200px;
    /* height: 100px; */
    margin-bottom: 10px;
    padding-top: 10px;
}
.item-flex-box{
    flex-direction: row;
}
@media only screen and (max-width: 640px){
    .item-flex-box{
        flex-direction: column !important;
        padding-bottom: 30%;
    }
    .desc-box{
        height: 600px !important;
    }
    .item-det-box{
        width: 100% !important;
        
    }
    .search-bar{
        width: 40vw;
    }
}