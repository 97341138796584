.profile-circle{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: 3px solid rgba(38, 63, 105, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    position: relative;
}
.profile-circle-2{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    position: absolute;
    width: 50%;
    left: 19%;
    bottom: 20%;
    overflow: hidden;
    background-color: brown;
}
.bg-1{
    background-color: rgba(0, 29, 79, 0.85);
    color: white !important;
}
.bg-2{
    background-color: rgba(0, 29, 79, 0.6);
    color: white !important;
}
.bg-3{
    background-color: rgba(0, 29, 79, 0.4);
    color: white !important;
}
/* .bg-bk{
    background-color: black;
    color: white !important;
} */
.lb-block{
    background-color: #bec1cce3;
    /* width: 80%; */
    height: 30px;
    padding-top: 8px;
    border-radius: 8px;
    color: white;
    font-weight: 800
}
.hexagon {
    width: 100px;
    height: 57.735px;
    /* background: red; */
    position: relative;
    
  }
.hexagon::before {
    content: "";
    position: absolute;
    top: -28.8675px;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 28.8675px solid none;
  }
.hexagon::after {
    content: "";
    position: absolute;
    bottom: -28.8675px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 28.8675px solid none;
  }
.rank{
    position: absolute;
    bottom: -8px;
    left: -2px;
    z-index: 20;
    font-weight: 700;
    color: white;
    background-color: rgba(0, 0, 0, 0.726);
    height: 16px;
    width: 16px;
    font-size: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-bar-lb{
    border: 1px solid rgb(204, 203, 203);
    height: 38px;
    /* width: 59vw; */
    border-radius: 18px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.979) !important;
    padding-left: 4%;
}
.gods-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.gods-cont-h{
    background: -webkit-linear-gradient(#FFE553, #C89612);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
    font-size: 30px;
}
.hexagon1 span {
    position: absolute;
    display: block;
    float:left;
border-left: 170px solid #fff;
border-right: 170px solid #fff;
}

.top1 {
top: 0;
border-top: 0px solid transparent;
 border-bottom: 75px solid transparent;
}

.bottom1{
bottom: 0px;
border-bottom: 0px solid transparent;
border-top: 75px solid transparent;
}

.hexagon1 {
    
    width: 340px;
    height: 295px;
    position: relative;
    display: inline-block;
}