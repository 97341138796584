.tx-fyx{
    color: #5200E5;
    cursor: pointer;
}
.tx-fyx:hover{
    color: rgb(128, 128, 238) !important;
}
.btn-fyx{
    color: white !important;
    background-color: #5200E5 !important;
}
.btn-fyx:hover{
    color: white !important;
}
.tx-error{
    text-align: left !important;
    color: rgb(245, 82, 82) !important;
}
.blur{
    opacity: 20%;
}