
.wallet{
    text-align: left !important;
    width: 100%;
    height: 100% !important;
}
.wal-popup{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
    align-items: center
}
.pop-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(202, 198, 198, 0.459);
    display: flex;
    z-index: 5;
    justify-content: center;
    align-content: center;
    align-self: center;
    align-items: center
}
.tx-card{
    background-color: #F3F6F9 !important;
}
.pop-content{
    z-index: 6;
    background-color: honeydew;
    width: 40%;
    height: 80%;
    overflow-y: scroll;
    min-width: 400px !important;
    position: relative;
}

.bg-grey{
    background-color: rgba(128, 128, 128, 0.274) !important;
}
.marketplace-banner{
    width: 73vw !important;
    height: auto !important;
}
.curr-dd{
    width: 100%;
    /* height: 140px; */
    font-size: 10px;
    /* background: red !important; */
  }
  .curr-dd .css-yk16xz-control{
    height: 48px;
    border-radius: 8px;

  }
  .curr-dd div{
    background: #f5f8fa !important;
    border: unset;
    color: black;
    align-items: center;
    /* justify-content: center; */
  
  }
  .cs-mg{
      margin-left: 10px;
      cursor: pointer
  }
  .action-icon-but{
    width: 40px;
    height: 40px;
    background-color: #3699FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    }
    .mob-balance-icons{
        display: block;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        align-content: center;
        width: 60px;
        font-weight: 700;
    }
    .mob-button-hide{
        display: block;
    }
    .auth-head-white{
        color: black
    }
    .action-box-content{
        min-width: 100% !important;
        /* background-color: black !important; */
        /* color: white !important; */
    }
    .action-box-content:hover{
        box-shadow: 0px 0px 10px grey;
    }
    .auth-head-white{
        /* color: white !important; */
    }
    .close{
        position: absolute;
        right: 10px;
        cursor: pointer
    }
    .fiat-custom-dd div{
        max-height: 200px;
    }
    .shrink-popup{
        height: unset !important;
    }
@media only screen and (max-width: 500px){
    .pop-content{
        width: 90%;
        min-width: unset !important
    }
    .marketplace-banner{
        width: 94vw !important;
        height: 80px !important;
    }
    .mob-buttons-balance{
        /* flex-direction: column !important; */
        /* justify-content: space-between !important; */
        /* align-items: left; */
    }
    .cs-mg{
        /* margin-left: unset */
    }
    .new-button-custom{
        min-width: 100px !important;
        
    }
    .indicator-label{
        font-size: 16px !important
    }

    .mob-button-hide{
        display: none
    }
    .action-box-content{
        min-width: 100% !important;
        background-color: black !important;
        color: white !important;
    }
    .auth-head-white{
        color: white !important;
    }
}