/* tooltip */
@import url('https://fonts.googleapis.com/css?family=Metropolis');
@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
  /* background: linear-gradient(180deg, #18002D 0%, #262966 100%); */
  height: 100vh;
  position: relative;
  display: flex;
  background-color: #EDEDED  ;

  flex-direction: row;
  /* background-color:#161616 ; */
}
/* .bg-bk{
  background-color: #060606 !important;
  color: white !important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  background-color: black !important;
}
.card{
  background-color: #060606 !important;
  color: white !important
}
.card-rounded{
  background-color: #060606 !important;
  color: white !important
}
.bg-bk-2{
  background-color:#161616 !important;
  color: white !important
} */
/* classes to keep rest remove */
/* .progressModal */
.if img{
  height: 100%;
  width: auto;

}
.new-button-custom:hover{
  opacity: 0.9 !important;
  font-weight: 700 !important;
}

.menu-drawer{
  display: flex !important
}
.mobile-menu{
  display: none !important
}
table{
  border-collapse: collapse !important;
}
.videoModalView{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-end;
  align-items: center;
}
.videoPopupScreen{
  width: 80vw !important;
}
.paginationButtons{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  margin-bottom: 5%;
}
.videoCard{
  object-fit: fill !important;
  width: 100% !important;
  height: auto !important;
}
.videoBox{
  width: 280px !important;
  font-weight: 900;
  background-color: #F6F6F6;
  font-size: 14px !important;
  margin-right: 10px;
  /* margin-right: 20px; */
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.ribbon-l{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  /* background-color: #f2721cd2; */
  box-shadow: 0px -1px 5px 0px rgba(#000, 0.1);
  color: theme-inverse-color("primary");
  top: 5%;
  right: -5%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transform: translateX(5px) translateY(-50%);
}
.custom-card{
  transition: .8s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}
.custom-card:hover{
  transform: scale(1.05);
}
.inactiveTab{
  color: rgba(128, 128, 128, 0.315) !important;
  font-size: 18px !important;
}
.transactionsTable{
  display: flex;
  flex-direction: row;
  width: 100%;
  /* background-color: hotpink; */
  margin-left: 10%;
  margin-right: 20% !important;
  margin-top: 2%;
  font-size: 12px;
  text-transform: uppercase;
}
.tableHeader{
  color: #36285C !important;
  font-weight: 600;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-decoration: unset !important;
  text-transform: unset !important;
  opacity: unset !important;
}
p{
  color: 'black' !important;
}
.tableElement1{
  width: 10%;
  font-weight: 600;
  color: #120D3F;
  opacity: 0.55;
}
.tableElement2{
  width: 30%;
  font-weight: 600;
  /* background-color: violet; */
}
.tableElement3{
  width: 10%;
  color: #4D00D5;
  font-weight: 600;
}
.tableElement4{
  width: 20%;
  color: #4D00D5;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
  text-transform: lowercase;

}
.verticalLine{
  border-left: 1px solid black;
  height: 20px;
  /* background-color: violet; */
  margin-left: 24.5%
}
.depositString{
  color: #120D3F;
  opacity: 0.55;
}
.tableElement5{
  width: 10%;
  font-weight: 600;
}
.tableElement6{
  width: 10%;
  cursor: pointer;
}
object{
  pointer-events: none;
}
.welcomeWallet{
  text-align: left;
  font-size: 22px;
  margin-top: 2%;
  color: grey;
  margin-left: 10%;
  font-weight: 900;
}
.searchCont{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20% !important;
  padding-top: 10px;
}
.searchBar{
  width: 200px !important;
  text-align: center;
  border: 1px solid grey !important;
}
.searchIcon{
  background-color: #5200E5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  border-radius: 20px;
}
.walletActionsHeading{
  text-align: left;
  font-size: 12px;
  font-weight: 800;
  margin-top: 2%;
}
.walletSectionIcon{
  max-width: 30%;
  min-width: 30%;
  flex-wrap: wrap;
  /* width: 30%; */
  margin-top: 5%;
  background-color: white;
  border-radius: 8px;
  margin-right: 5%;
  padding-bottom: 2%;
  color: 'black' ;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
}
.walletSectionIconPopup{
  cursor: pointer;
  max-width: 50%;
  flex-wrap: wrap;
  margin-top: 5%;
  background-color: white;
  border-radius: 8px;
  margin-right: 5%;
  padding-bottom: 2%;
  background-color: #EEEEEE !important;
  font-weight: 700;
}

.walletSectionIconPopup:hover{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  }
.walletIcons{
  width: 95%;
  height: auto;
  margin: 20% 2.5%;
}
.itemViewModal{
  width: 70% !important;
  height: 100% !important;
  padding-bottom: 10%;
  margin-top: unset !important;
}
.walletViewModal{
  width: 70% !important;
  height: 70% !important;
  margin-top: 5% !important;
  padding-bottom: 10%;
  /* margin-top: unset !important; */
}
.popup-img{
  height: 100%; 
  width: 100%;
  object-fit: contain;
}
.fullItemView{
  min-width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(128, 128, 128, 0.37);
}
.customMobAttachments{
  flex-wrap: wrap;
}
.giCont{

  width: 96vw !important;
  max-width: 1590px;
  padding-left: 333px;
  text-align: left;
}
.inactivePagination{
  background-color: grey !important;
}
.listImg{
  width: 47px;
  height: 47px;
  border-radius: 99px;
  object-fit: cover;
  margin-top: 1.5%;
  margin-left: 6px;
}
.gameDets{
  display: flex;  
  
  /* padding-left: 280px; */
  flex-direction: row;
}
.gameInfo{
  /* display: flex; */
  width: 35vw;
  /* border: 1px solid pink; */
}
.gameInfo2{
  /* display: flex; */
  margin-left: 5%;
  width: 30vw;
  /* border: 1px solid pink; */
}
.gameInfoHead{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}
.infoInactive{
  color: #B5B2C9;
  /* font-size: calc(2vw +1vh); */
  margin-left: 5.5%;
  font-weight: bold;
  cursor: pointer
}
.infoActive{
  color: #20155C;
  /* font-size: calc(2vw +1vh); */
  margin-left: 2%;
}
.gameInfoBox1{
  background-size: cover !important;
  border-radius: 22px;
  margin-top: 5%;
  width: 100%;
  height: 200px;
  max-height: 200px;
  padding-left: 5%;
  position: relative;
}
.gameInfoBox2{
  margin-top: 8%;
  margin-left: 2%;
  width: 90%;
  height: 200px;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 22px;
  background-color: #F6F6F6;
}
.downloadLoggedin{
  display: flex;
  flex-direction: row;
}
.glcsImage{
  max-width: 1260px;
  height: 50vh;
  margin-top: 1%;
  position: relative;
}
.csTemp{
  color: #20155C;
  position: absolute;
  text-align: center;
  font-size: 33px;
  font-weight: 900;
  text-transform: uppercase;
  width: 100%;

  height: 50vh;
  top: 0;
  left: 0;
  /* border: 1px solid pink; */
  z-index: 1;
  
}

.tu{
  z-index: 10;
  background-color: rgba(43, 37, 88, 0.0);
  
}
.gib2Val{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  color: #B5B2C9;
}
.gib2Val1{
  color: #140A48 !important;
}
.gib2Val2{
  color: #B5B2C9;
}

.attachProp{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 45vw !important;
}
.additionalFilesObj{
  border: 1px solid grey;
  border-radius: 4px;
  margin-right: 2%;
  text-align: unset;
  margin-bottom: 2%;
  object-fit: contain;
}

.additionalFilesObj > #document > html {
  border: 5px solid red !important;
}

.mobdropdownnone{
  display: none
}
.mobdropdown{
  display: block;
  height: 100%;
  background-color: #1df53a;
}
.comingSoon{
  margin-left: 260px;
  width: 90vw;
  height: 100vh;
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  position: relative;
  
}
.comingSoonImg{
  height: 100vh;
  width: 100vw;
    filter: blur(5px);
  -webkit-filter: blur(5px);
}
.comingSoon::after {
  content: '⚡ Coming Soon ⚡';
  text-transform: uppercase;
  font-weight: 900 !important;
  font-size: 8vh !important;
  white-space: nowrap;
  position: absolute;
  color: #140A48 !important;
  /* z-index:2; */
  top: 50%;
  left: 35%;
  /* width: 128px; */
  
  transform: translate(-104px, -50px);
  text-align: center;
  height: 20px;
  
  
  font-size: 20px;
  font-weight: bolder;
}
.comingSoonParent{
  width: 100vw;

}

.comingSoonText{
  /* position: absolute; */

  color: white;
  font-size: 20px;
  font-weight: bolder;
  height: 24px;
  width: 100vw;
  transform: translateY(-12px);
  transform: translateX(-50vw)

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.navbar-mob{
  display: none;
}
.nav-top{
  background: #19012E;
  width:280px;
  height: 100vh !important;
  position: fixed;
  padding-top: 3%;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 15%;
  z-index: 1;
  overflow-y: scroll;
}
.navbar-img{
  width: 13px;
  height: auto;
  margin-right: 10%;
}
.fyx-logo{
  width: 133px;
  margin-bottom: 10%;
}

.logout{
  margin-top: 10%;
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 99px;
  color: white;
  background-color: #5200E5;
  font-weight: 900;
  text-transform: uppercase;
}
.open-beta{
  height: 18px;
  border-radius: 12px;
  background: linear-gradient(180deg, #441DF5 0%, #0622F8 100%);
  margin-top: 5%;
  color: white;
  font-weight: 750;
  width: 90%;
  margin-left: 5%;
  font-size: 12px;
  margin-bottom: 10%;
}
.nav-element{
  color: white;
  font-weight: 200;
  font-family: monospace;
  /* height: 50px; */
  border-bottom: 1px solid #2C0252;
  /* background-color: white; */
  padding-top: 4%;
  padding-bottom: 5%;
  text-align: left;
  padding-left: 11%;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 2px !important;


}
.inactive{
  color: rgba(255, 255, 255, 0.336);
}
.active{
  background: linear-gradient(90deg, rgba(68, 29, 245, 0.47), rgba(6, 34, 248, 0.02));
}
.header{
  margin-top: 2%;
  margin-bottom: 2%;
}
.footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-top: 5%;
  bottom: 5%;
  left: 45%;
  width: 10%;
  color: rgb(57, 167, 211);
}
.link{
  text-decoration: none !important;
  color: #282c34 !important;
}
.game-box{
  width: 40vw;
  height: 15vw;
  background-color: white;
  /* border: solid black 1px; */
  margin-left: 30vw;
  margin-bottom: 1%;
  -moz-box-shadow: 0 0 5px grey;
  -webkit-box-shadow: 0 0 5px grey;
  box-shadow: 0 0 5px grey ;
  padding-top: 2%;
  text-decoration: none !important;
}
.my__carousel_main{
  height: '100%';
  width: 90vw;
  max-width: 1840px;
  /* font-size: 1.1vw; */
  padding-left: 333px;
}
.customtable{
  vertical-align: unset !important;
}
.customtbody{
  border-top: unset !important;
}
.inputfield{
  width: 250px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid rgb(218, 214, 214);
  margin-top: 2%;
  
}
.walletTest > .my__carousel_main {
  width: auto !important;
  font-size: 1.1vw;
  /* padding-left: 230px; */
}
/* .wallet-custom{
  background-color: white !important;
  
} */
.carousel{
  height: '100vh' !important;
  /* color: black; */
  /* padding-top: 5%;
  /* padding-bottom: 5%; */
  padding-bottom: 5%;
}
.carousel-item li {
  background-color: yellow; 
}
.carousel-item{
  padding-top: 5%;
  padding-bottom: 5%;
}
.listCont {
  border-bottom: 2px solid #e3e1f5;
  padding-bottom: 23px;
}
.win{
  background-color: rgb(42, 165, 134);
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 4px;

}
.score{
  margin-top: 5%;
  font-weight:700;
  margin-bottom: 1%;
  margin-left: 13px;
  font-size: 5vh;
  font-weight: 900;
  color: #20155c;
  text-align: left !important;
}
.popup{
  background-color: gold;
}
.leaderboardCarousel{
  padding-left: 270px !important;
  padding-bottom: 10%;
}
.tableHeadersVal{
  font-size: 0.7em;
  font-weight: 700;
  max-width: 1344px;

  background-color: #441DF5;
  border-radius: 99px;
  color: #FFF;

  display: flex;
  flex-direction: row;
  
  padding-top: 1%;
  padding-bottom: 1%;
  margin-left: 73px;
  margin-right: 5%;
  margin-top: 1%;

  /* width: 92vw !important; */

}
.tableHeaders{
  color: #19012E;
  display: flex;
  flex-direction: row;
  border-radius: 99px;
  align-items: center;
  background-color: #EAEAF4;
  grid-template-columns: 1fr 1fr 0.5fr 1fr 0.65fr 0.3fr 0.3fr 0.3fr;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1%;
  position: relative;

}
.liveDot{
  animation: blinkers 3s linear 80;
  color:rgb(57, 167, 211);
  margin-top: 2%;
  margin-left: 2%;
}
.walletHeading{
  color: black;
  font-weight: 900;
  text-align: left;
  font-size: 1.8vw;


}
.walletTab{
  display: flex;
  flex-direction: row;
  padding-left: 10%;
  padding-right: 10%;
  justify-content: space-between;
}
.walletHeadingCont{
  min-width: 30vw;
  
  /* background-color: grey; */
  padding: 5% 5%;
  margin: 5% 1%;
  border-radius: 8px;
  background-color: #F6F6F6;

}
.customWalletInputContainers{
  width: 40px !important;
}
.walletHeadingCont2{
  min-width: 37vw;
  /* background-color: grey; */
  padding: 2% 5%;
  margin: 5% 0%;
  margin-left: 5% !important;
  border-radius: 8px;
  background-color: #F6F6F6;

}
.balanceContWallet{
  color: #5200E5;
  font-weight: bolder;
  font-weight: 900;
  text-align: left;
  display: flex;
  flex-direction: row ;
  margin-top: 2%;
  font-size: 2.4vw;
}
@keyframes blinkers {
  50% {
    opacity: 0;
  }
}
.animate{
  animation: mymove 2s 1;
  animation: blinker 3s linear 5;
}
@keyframes mymove {
  from {left: -200px;}
  to {left: 0px;}
}
@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}
.box-text{
  color: #372177;
  font-size: 0.8em;
  font-weight: 600;
}
.box-font{
background: #372177;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.bannerLeaderboard{
  width: 70vw;
  height: auto;
  /* margin-top: 5%; */
  /* margin-left: 73px; */
  border-radius: 22px;
  margin-bottom: 10px;
}
.name-box{
  display: flex;
  flex-direction: row;
  margin-left: 8vw;
}
.timeline{
  font-size: 2.75vh !important;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding-right: 4%;
  margin-bottom: 5%;
  
}
.player-box{
  width: 20vw;
  height: 30vw;
}
.action-box{
  font-family: system-ui !important ;
  width: 35vw;
  
  /* height: 5vw; */

  /* padding-top: 2%; */
  border-radius: 14px;
  padding-left: 2%;
  padding-right: 2%;
  /* padding-bottom: 1%; */
  /* margin-bottom: 2%; */
  /* font-size: 0.7vw !important; */
  /* background-color:  #EAEAF4; */
}
.started{
  margin-left: 0% !important;
}
/* .progress-custom {
  background-color: purple !important;
  color: white;
} */
.test-p{
  background-color: #e0dfe4 !important;
  width: 80%;
  margin-top: 2%;
  margin-bottom: 2%;
  height: 10px !important;
}
.healthProgressBar{
  /* background-color: rgba(68, 29, 245, 0.33) !important; */
}
.timeline-box{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 3%;
  position: relative;
  /* width: 130%; */
}
.started{
  width: 32.4vw;
  margin-left: -1%;

}
.player-dets{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10%;
  font-weight: bold;
}
.skills{
  font-weight: normal;
  text-decoration: #282c34;
  font-size: 10px
}
.progressBarProfile{
  margin-left: 5%;
}
.summary-cont{
  color: black;
  /* background-color: #140A48; */
  display: flex;
  flex-direction: row;
  /* padding-top: 5%; */
  width: 100%;
}
.timeline{
  width: 400px;
  padding-right: 2%;
  font-family: system-ui !important;
  /* background-color: gray; */
  font-size: 2.5vh;
  /* margin-right: 5%; */
  font-weight: bold;
  text-align: left;
  /* height: 80vh; */
  
  flex-direction: column;
  
}
.timelineVideo{
  color: #372177;
  margin-right: 3%;
  width: 50vw;
  font-family: system-ui !important;
  font-size: 2.5vh;
  font-weight: bold;
  /* text-transform: lowercase; */
  text-transform: capitalize;
}
.videoCard{
  border-radius: 8px;
}
.battleVideoCard{
  width: 33vw;
}
::-webkit-scrollbar {
  width: 3px !important;
}
.timelineCont{
  height: 80vh;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  padding: 10% auto !important;
}
.summary-line{
  position:absolute;
  width: 1px;
  left: 16.2%;
  z-index: 10;
  top: 12.5px;
  background-color: rgb(212, 209, 209);
  height: 10px;
  display: none;
}
.timelineCont:hover{
  overflow-y: scroll;
  overflow-x: hidden;
}
.participants{
  font-size: 2.75vh !important;
  font-weight: 900;
  color: #372177;
  font-family: system-ui !important;
  width: 20%;
  text-align: left;
  font-size: 2.5vh;
  font-weight: bolder;
  

}
.player-details{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  margin-top: 5%;
  margin-bottom: 20%;
  padding-right: 20%;
  
}
.fight-details{
  font-size: 16px;
  font-weight: 600;
  line-height: 33px;
  background-color: #F6F6F6;
  border-radius: 22px;
  width: 100%;
  padding-left: 5%;
  padding-top: 3%;
  margin-bottom: 10%;
  margin-top: 5%;
  padding-bottom: 5%;
  font-family: system-ui !important;
}
.colored-name1{
  color: #a540e9 !important;
  font-weight: 700 !important;
}
.colored-name2{
  color: #598cda !important;
  font-weight: 700 !important;
        
}
.colored-name3{
  /* color: black; */
  position: relative;
}
.login-form{
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-top: 100px;
  padding-left: 400px;
  width: 500px;
  color: #19012E;
  width: 100vw;
  height: 100vh;
  /* border: 1px solid red; */
  background: url(./assets/login-background.png);
}
.loginSemiImg{
  width: 50%;
  height: 100%;
  object-fit: cover;

  
}
.loginSemiWallet{

  position: relative;
}
.loginSemi{
  display: flex;
  align-items: center;
  width: 60vw;
  height: 80vh;
  border-radius: 22px;
  background: white;
  overflow: hidden;
}
.loginSubCont{
  width: 30vw;
  padding: 10%;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  
}
.login-label{
  display: flex;
  flex-direction: column;
  align-items: left;
  color: #19012E;
}
.paginationCont{
  /* background-color: rgba(0, 0, 0, 0.11); */
  width: 80%;
  margin-bottom: 10%;
}
.butY{
  
  margin-top: 0.4% !important;
  color: white;
  font-size: 12px !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  background: #5200E5;
  width: 148px !important;
  /* text-align: center; */
  border-radius: 64px;
  height: 28px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: unset !important;
  align-items: center;
  margin-right: 20%;
  
}
.dButton1{
  width: 200px !important; 
  font-size: 12px !important;
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
.dButton2{
  width: 200px !important; 
  margin-left: 2%;
  font-size: 12px !important;

  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;

}

.button, .meltButton2, .createItemButton, .dwnldButton{
  text-align: center;
  line-height: 33px;
  font-size: 16px;
  background-color: #5200E5;
  /* background-color: rgb(238, 216, 92); */
  color: white;
  border: none;
  width: 250px;
  height: 35px;
  border-radius: 99px;
  align-content: center;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
}
.meltButton2{
  margin-bottom: 2%;
}
.button:hover, .meltButton2:hover, .createItemButton:hover, .dwnldButton:hover {
  background-color: #19012e;
  color: #fff;
}
.button, .button2, .createItemButton, .browseButton {
  -webkit-transform: perspective(1px) translateZ(0);
  transition: transform;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  cursor: pointer;
}
.button:active, .button2:active, .createItemButton:active, .meltButton2:active, .dwnldButton:active {

  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}
.tooltip{
  background-color: gold;
  color: black;
}
.button2, button.but.modalBut, .browseButton{
  text-align: center;
  line-height: 33px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  background-color: gold;
  font-weight: 900;
  color: black;
  border: none;
  width: 250px;
  height: 35px;
  border-radius: 99px;
  align-content: center;
  cursor: pointer;
  text-transform: uppercase;

}
.button2:hover, .meltButton2:hover {
  background-color: #007bff;
  color: #fff;
}
.text-field{
  color: #2D2039 !important;
  width: 250px;
  height: 30px;
  border-radius: 99px;
  color: #19012E;
  border: none;
  margin-top: 1%;
  background-color: #DFDFDF;
  padding-left: 8px;
  text-align: center;
  font-weight: 700;
}

.fp{
  text-align: center;
  margin-top: 1%;
  margin-bottom: 3%;
  font-weight: bold;
  font-size: 12px;
}
.fp2{
  text-align: center;
  margin-top: 1%;
  font-weight: bold;
  font-size: 12px;
}
.click-here{
  color: #441DF5;
  cursor: pointer;

}
.login-heading {
  color: #2D2039;
  font-size: 20px; 
  font-weight: 900;
  text-align: center;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 5%;
  margin-top: 3%;
  
}
.DepositLabel {
  color: #2D2039;
  font-size: 20px; 
  font-weight: 900;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  /* margin-bottom: 5%; */
  margin-top: 3%;
  
}
.sel{
  background-color: #F6F6F6;
  width: 90px;
  height: 80%;
  color: #19012E;
  border-radius: 4px
}
.itemViewBox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 12%;
}
.itemH{
  font-size: 1.7em;
  font-family: system-ui !important;
  font-weight: 800;
  text-align: left;
  margin-bottom: 4%;
  padding-left: 12%;
  color: #20155C;
}
.itemSection{
  color: #19012E;
  /* margin-left: 10%; */
}
.customIb{
  height: 12vw !important;
  padding-top: 5%;
  min-width: 10vw !important;
  font-size: 10px !important;
  flex: 1;
}
.customIb2{
  height: 100px !important;
  min-width: 120px !important;
  flex: 1;
  text-align: left;
  padding-left: 2%;
  color: #19012E;
  /* color: #dcdbdd; */
  /* font-weight: bold; */
  font-size: 1.0em !important;
  /* padding-top: 10%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.customIm{
  width: 100px !important;
  height: 145px !important;
  margin-top: 5%;
}
.performanceSection{
  /* margin-left: 10%; */
}
.fighterValues{
  display: flex;
  flex-direction: row;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #19012E;
}

.App-link {
  color: #61dafb;
}
.summary-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
  padding-left: 8%;
  padding-right: 8%;
}
.fighter-name{
  color: #372177;
  font-weight: 900;
  margin-top: 5%;
  margin-bottom: 3%;
  font-size: 1.9vh !important;
}
.name-ext{
  font-size: 1.9vh !important;
  font-weight: 900;
}
.avtr{
  width: 70%;
  border-radius: 50%;
  font-weight: bolder;
  text-decoration: bold;
  height: auto;
  
  /* border-radius: 14px; */
}
.small{
  width: 5vh !important;
  height: 5vh;
  border-radius: 2vw !important;
  margin-right: 3%;
}
.red-dot{
  position: absolute;
  z-index: 4;
  width: 1vw;
  height: 1vw;
  border-radius: 1vw;
  margin-left: 5%;
  background-color: red;
}
.avtr-small{
  width: 3.5vw;
  font-weight: bolder;
  text-decoration: bold;
  height: auto;
  border-radius: 14px;
}
.avtr-det{
  display: flex;
  flex-direction: column;
  color: #19012E !important;
  margin-right: 3% !important;
  background-color: #F6F6F6;
  border-radius: 22px;
  width: 120px;
 
  align-items: center;
  padding-top: 3%;
  padding-bottom: 10%;

}
.vs{
  font-size: 4vw;
  font-weight: bolder;
}
.nav-tabs .nav-link.active{
  background: none !important;
  font-size: 16px !important;
  font-weight: 700;
  background-color: white !important;
  color: #5200E5 !important;
}
.nav-tabs{
  font-size: 15px !important;
  color: black !important;
}
.nav-tabs a{
  color: black !important;

}
.action-box-headline{
  margin-top: -9px;
  line-height: 25px;
  font-size: 10px!important;
  font-weight: 600;
  font-family: system-ui;
}


.dot-red{
  display: inline-block;
  margin-right: 3%;
  /* position: absolute; */
  z-index: 1;
  /* min-width: 5px;
    position: absolute;
  z-index: 4;
  min-height: 5px;
  max-width: 5px;
  max-height: 5px; */
  width: 5px;
  height: 5px;
  margin-left: 2%;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 0px 0px 3px #1BC5BD;
}
.dot-blue{
  display: inline-block;
  margin-right: 3%;
  /* position: absolute; */
  z-index: 4;
  /* min-width: 5px;
  min-height: 5px;
  max-width: 5px;
  max-height: 5px; */
  width: 5px;
  height: 5px;
  margin-left: 2%;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 0px 0px 3px rgb(81, 81, 168);
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
}
.summary-ts{
  /* background-color: hotpink; */
  /* padding: 5px */
  /* color: white; */
  font-size: 10px;
  width: 50px;
  display: inline-block;
  /* width: 40px; */

}
.timestamp{
  /* font-size: 2.1vw !important; */
  font-size: 0.65vw !important;
  font-family: system-ui !important;
  letter-spacing: 1px;
  color: #65578F;
  padding-top: 1.5%;
  padding-bottom: 3%;
}
.fighterCont{
  display: flex;
  flex-direction: column;
  color: #372177;
  margin-left: 300px !important;
  padding-top: 5%;
  padding-left: 2%;
  padding-bottom: 5%;
  /* border: 1px solid red; */
  width: 45%;
  
}
.rightCol{
  width: 30%;
  height: 100%;
  margin-top: 5.5%;
  /* border: 1px solid yellow; */
}
.fighterDetails{
  /* background-color: teal; */
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 4%;
  margin-top: 10%;
  
}
.fighterHead{
  font-size: 2.75vh;
  font-weight: 900;
  text-align: left;
  margin-bottom: 4%;
}
.fighterHp{
  margin-top: 1%;
  background-color: #F6F6F6;
  width: 26vw;
  border-radius: 12px;
  padding-left: 5%;
}
.fighterDetCont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #19012E !important;
  margin-right: 3% !important;
  background-color: #F6F6F6;
  border-radius: 12px;
  width: 16vw;
  height: 14vw;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;

}
.fighterStats{
  width:20vw;
  text-align: left;
  margin-left: 2% !important;
}
.battleHistory{
  color: #19012E;
}
.fighterSpan{
  font-weight: 700;
  font-family: system-ui;
  font-size: 15px !important;
  /* color: gold; */
  color: #372177;
  min-width: 80px;
  /* background-color: green; */
  margin-right: 3%;
}
.skills{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 3%;
  padding-top: 10px;
  
}
.skillList{
  /* background-color: rgba(0, 128, 128, 0.358); */
  /* width: 170px; */
  display: flex;
  flex-direction: rwo;
  text-align: center;
  border-radius: 4px;
  margin-right: 3%;
  font-size: 15px;
}
.profileTableHeaders{
  /* width: unset !important; */
}
.explorerTableHeaders{
  margin-left: 30px !important;
  width: unset !important;
}
.marketplaceName{
  color: grey;
  padding-top: 0.7%;
  font-size: 1.0em;
  font-weight: 900;
  cursor: pointer

}
.marketplaceNameIn{
  color: grey;
  opacity: 0.5;
  margin-left: 3;
  padding-top: 0.7%;
  font-size: 0.9em;
  font-weight: 700;
  cursor: pointer

}
.itemView{
  cursor: pointer;
  margin-left: 333px;
  margin-top: 20px;
  width: 100%;
  font-family: system-ui !important;
  color: #19012E;
  padding-top: 1%;
  align-content: center;
}
.collDDown{
  width: 200px;
  height: 24px !important;
  color: black;
  font-size: 12px !important;
}
.itemBoxDesc{
  /* background-color: gold; */

  text-transform: uppercase;
  font-size: 11px !important;
  font-weight: bold;
  color: rgba(180, 179, 179, 0.699)
}
.roundedc{
  border-radius: 16px !important
}
.btn{
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}
.btn-out-col{
  color: #cb0c9f !important;
  border-color: #cb0c9f !important;
}
.btn-out-col:hover{
  background-color: none !important;
}
.marketplaceItemContainer{
  margin-top: 1% !important;
  margin-bottom: 5% !important;
  margin-left: unset !important
}
.price-card{
  background-image: linear-gradient(
310deg, #7928CA 0%, #FF0080 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.myItemsView{
  margin-top: 50px !important;
}
.itemDivider{
  display: flex;
  flex-direction: row;
  font-size: 2.75vh;
  margin-left: 5%;
  font-weight: 600;
  margin-bottom: 10%;
  /* border: 1px solid white; */
}
.dividerCus{
  display: flex;
  margin-left: 1% !important;
  margin-top: -222px;
  align-items: flex-start;
  flex-direction: column;
}
.myItems{
  /* width: 150px; */
  height: 30px;
  padding-top: 1%;
  padding-bottom: 2%;
  margin-right: 5%;
  opacity: 0.5;
  
}
.allItems{
  /* width: 150px; */
  opacity: 0.5;
  height: 30px;
  padding-top: 1%;
  padding-bottom: 2%;
  padding-right: 5%;
  padding-left: 5%;
}
.itemSelected{
  border-radius: 8px;
  /* background-color: rgba(90, 83, 108, 0.397); */
  opacity: 1;
}
.itemTypeBanner{
  display: flex;
  flex-direction: row;
  /* padding-left: 2%; */
  /* background-color: red; */
  width: 70%;
  font-size: 11px;
  font-weight: bold;
  margin-top: 2%;
  color: rgba(255, 255, 255, 0.365);
  
}
.divCus{
  margin-bottom: 5%;
}
.itemType{
  margin-right: 3%;
  color: #19012E;
  cursor: pointer;
}
.search{

  width: 320px;
  height: 30px;
  border: none;
  border-radius: 14px;
  background-color: unset;
  text-decoration: none;
  border: 1px solid grey;
  color: #19012E;
  text-align: center;
  display: flex;
  flex-direction: row;
}
.searchBarItems{
  width: 80%;
  height: 100%;
  border: none;
  background: transparent;
  text-align: center;
}
.iconSearch{
  width: 20%;
  height: 100%;
  background-color: #4F42C0;

  ;
  border-radius: 32px;
}
.searchFa{
  height: 100%;
  /* width: 100%; */
}
.itemContainer{
  width: 95%;
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1%;
  margin-bottom: 15%;
}
.scrollview{
  max-width: 1495px;
}
.imageWatermark{
  position: absolute;
  bottom: 15%;
  right: 5px;
  /* z-index: 12; */
  color: #F6F6F6 !important;
  font-size: 12px;
  line-height: 11px;
}
.searchBarCont{
  display: flex;
  width: 80%;
  /* background-color: rgba(0, 0, 0, 0.11); */
  justify-content: center;
}
.itemBox{
  /* display: flex;
  padding-top: 5%;
  justify-content: flex-start;
  flex-direction: column; */
  font-weight: 900;
  background-color: #F6F6F6;
  font-size: 14px !important;
  width: 188px;
  margin-right: 10px;
  /* margin-right: 20px; */
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  position: relative;
}
.itemBox > div > span {
  font-size: 33px !important;
  font-weight: 600 !important;
  display: flex;
  padding-top: 5%;
  justify-content: flex-start;
  flex-direction: column;
}
.itemBox > div {
  padding: 4px;
  color: #260082 !important;
  font-weight: bold;

}
.videoBox > div > span {
  font-size: 33px !important;
  font-weight: 600 !important;
  display: flex;
  padding-top: 5%;
  justify-content: flex-start;
  flex-direction: column;
}
.videoBox > div {
  padding: 4px;
  color: #260082 !important;
  font-weight: bold;

}
.checkboxSelector{
  position: absolute;
  top: 5%;
  
  background: transparent !important;
  border: 1.5px solid #4F42C0;
  
}
.displayNone{
  display: none;
}
.checkboxSelector2{
  
  top: 5%;
  background: transparent !important;
  border: 1.5px solid #4F42C0;
  
}
.checkboxSelector2[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #555555;
  border-radius: 3px;
  background-color:#140A48;

  ;
}
.checkboxSelector2[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
  /* background-color: transparent; */
}
input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #555555;
  border-radius: 3px;
  background-color:#140A48;

  ;
}
input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
  /* background-color: transparent; */
}
.createCollScreen{
  width: 100vw;
  height: 100vh;
}
.itemImage{
  width: 188px;
  height: 188px;
  object-fit: cover;
  /* border: 1px solid white; */
}
.selectedItemBox{
  border: 1.5px solid #12D8FF !important;
  opacity: 1 !important;
}
.modal-content{
  background-color: rgba(79, 79, 102, 0.79) !important;
  color: white !important;
  border-radius: 14px !important;
  margin-left: 280px !important;
  
}
.customDepositOverlay{
  
  margin-left: unset !important

  
}
.customDeposit{
  padding: 10% 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70vw !important

  
}
.customDepositWallet{
  display: flex;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  padding: 1% 1%;
  margin: unset !important;
  margin-left: 5% !important;
  display: flex;
  /* background-color: brown; */
  flex-direction: column;
  justify-content: center;
  width: 40% !important

  
}
.walletIcon{
  margin-left: 23%;
  font-size: 7vw;
  margin-bottom: 2%;
  color: #4D00D5;
}
.depositSelector{
  display: flex;
  flex-direction: row;
  margin-left: 35vw;
  font-size: 15px;
  font-weight: bold;
}
.ddNew{
  font-size: 11px !important;
  font-weight: bold !important;
}
.depositValue{
  border-bottom: 1px solid black;
  width: 60px;
  margin:0% 2%;
  padding-left: 1%;
}
.modal-heading{
  /* background-color: rgba(36, 36, 131, 0.413); */
  
  border: none !important;
  width: 95% !important;
  height: 350px !important;
  display: flex;
  padding-right: unset !important;
  flex-direction: row;
}
.modalText{
  width: 100%;
  padding-top: 10%;
  padding-left: 10%;
}
.itemName{
  font-size: 20px;
  font-weight: bold;
}
.itemBoxFighter{
  height: 20vw !important;
  min-width: 15vw !important;
  margin-top: 5% !important;
  text-align: center;
  font-size: 1.5vw !important;
}
.itemImageFighter{
  height: 12vw !important;
  width: auto !important;
  margin-bottom: 20%;
  
}
.searchBar{
  /* margin-top: 2%; */
  /* margin-left: -5%; */
  width: 320px;
  height: 30px;
  border: none;
  border-radius: 14px;
  background-color: unset;
  text-decoration: none;

}
.handleDetails{
  /* border: 1px solid green; */
  width: 100%;
  display: flex;
  flex-direction: row;
}
.currencySelector{
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55vw;
  padding-left: 10%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
 

  
}
.currencySelectorBsv{
  min-width: 5vw;
  /* border: 1px solid yellow; */
}
.currentCurrency{
  background-color: blueviolet;
  padding: 0 5%;
  border-radius: 4px;
  min-width: 5vw;
  height: 20px;
}
.currencySelectDD{
  background-color: gold;
  /* padding: 0 5%; */
  margin-left: 5%;
  padding-left: 2%;
  border-radius: 2vw;
  width: 4vw;
  height: 20px;
  font-size: 14px !important;
  font-weight: bold;
  color: black;
  border: none;
}

.depositContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10%;
  margin-right: 5%;
  margin-top: 5%;
  margin-bottom: 10%;
}
.leaderBoardTable{
  background: #441DF5;
  color: white;
  font-weight: 700;
}
.helpButtonCont{
  display: flex;
  margin-top:2%;
  
  /* background-color: gold; */
}
.fighterImage{
  width: 40% !important;
  height: auto;
  margin-top: 5%;
}
.feedbackButton{
  border: none;
  /* position: absolute; */
  margin-top: 2%;
  padding: 5px 5%;
  font-size: 10px;
  font-weight: bold;
  border-radius: 14px;
  
}
.feedbackButton1{
  margin-right: 5%;
  border: 1px solid rgb(194, 192, 192);
}
.feedbackButton2{
  right: 6%;
  background-color: #5200E5;
  color: white;
}
.deposit{
  width: 30vw;
  height: 70vh;
  /* border: 2px solid green; */
  border-radius: 8px;
  /* margin-top: 2%; */
}
.deposit2{
  width: 30vw;
  height: 60vh;
  /* border: 2px solid green; */
  border-radius: 8px;
  /* background-color: rgba(76, 43, 226, 0.24); */
  /* margin-top: 2%; */
}
.viaPaymail{
  margin-top: 5%;
  margin-bottom: 5%;
}
.qrCode{
  /* margin-top: 5%; */
  height: 50% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.wdBut{
  margin-left: 5%;
  width: 60%;
  height: 25px;
  border: none;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  background: rgb(19,166,235);
  cursor: pointer;
background-color: gold;

}
.qrCodeText{
  text-align: left !important;
  margin-left: 2%;
  font-weight: 600;
  margin-bottom: unset !important;
  font-size: 10px !important;
  /* margin-top: 8%; */

}
.topupInputField::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
.paginationButton{
  border: none;
  background-color: #5e0fa3;
  border-radius: 8px;
  color: white;
  padding: 0 1%;
  margin: 0 1%;
}
.faCopy{
  background-color: #441DF5;
  width: 80px;
  border-radius: 12px;
  color: white;
  padding-top: 0.8%;
  cursor: pointer;
}
.fundCollector{
  width: 100%;
  background-color: rgba(165, 163, 163, 0.288);
  /* padding: 1% 5%; */
  font-weight: bold;
  font-size: 10px;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  min-height: 25px !important;
}
.depositOverlay{
  color: #19012E;
  text-align: left;
  font-weight: bolder;
  margin-left: 30%;
  
}
.balanceCont{
  margin-left: 10%;
  font-size: 16px;
  color: white;
  margin-bottom: 5%;
  text-align: left;
  font-weight: bold
}
.balanceText{
  color: gold
}
.withdraw{
  padding: 5% 10%;
}
.depositText{
  color: grey;
  font-size: 12px;
  text-align: center;
  margin-bottom: 5%;
}
.depositAmount{
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  margin-top: 5%;
}
.depositAmountInput{
  margin-top: 5%;
  width: 40%;
  align-self: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  height: 30px;
  border: 2px solid rgb(105, 63, 204);
  border-radius: 4px;
  color: white
}
.depositAddressInput{
  margin-top: 5%;
  color: #2D2039;
  width: 100%;
  align-self: center;
  text-align: center;
  background-color: #F6F6F6;
  height: 30px;
  border-radius: 12px;
  font-size: 12px;
  border-color: unset !important;
  outline: none !important ;
}
.depositAddressInput:hover{
  color: #b5b6de75;}

.withdrawButtons{
  display: flex;
  justify-content: center;
  margin-top: 10%;
  cursor: pointer;
}
.helpButton{
  width: 35%;
  height: 45px;
  border: none;
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.164) 0%, rgba(76,43,226,0.04) 70%);
  color: white;
  font-weight: bold;
  cursor: pointer;
}
span{
  cursor: pointer;
}
.withdrawButton{
  margin-left: 5%;
  width: 60%;
  height: 45px;
  border: none;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background: rgb(19,166,235);
  cursor: pointer;
background: linear-gradient(180deg, rgba(19,166,235,1) 6%, rgb(29, 44, 253) 84%, rgba(240, 69, 252, 0.521) 100%);
}
.nftContainer{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  color: white;
  font-weight: bold;
  margin: 7% 5%;
  /* margin-left: 5%; */
}
.nftForm{
  display: flex;
  flex-direction: row;
  margin-top: 8%;
  width: 30vw;
  /* border: 1px solid red */
}
.createNft{
  color: #2D2039;
  font-size: 31px;
}
.nftHeaders{
  text-align: right;
  width: 10vw;
  height: 30px;
  font-weight: 500;
  font-size: 12px;
}
.nftInput{
  margin-top: unset;
  margin-left: 2%;
}
.nftDesc{
  height: 20vh !important;
}
.previewPicker{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 28vh;
  margin-top: unset;
  position: relative;
  margin-left: 2%;
}
.filePicker{
  margin-top: unset;
  margin-left: 2%;
}
.pls{
  margin-top: 20%
}
.crossImage{
  position: absolute;
  right: -3%;
  top: -5%;
  
}

.csTemp > img {
  object-fit: scale-down;
  max-width: 1260px;
}
.imgPrev{
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}
.browse{
  margin-top: 0%;
  margin-bottom: 0%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; 
}
.additionalFilesList{
  background-color: #0d97ff;
  height: 29px;
  width: 105px;
  border-radius: 99px;
  margin-right: 3%;
  margin-bottom: 2%;
  text-align: center;
  padding-top: 1%;
  font-size: 10px;
}
.additionalFilesCont{
  display: flex;
  color: white;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-left: 144px; */
}
.backingInput{
  display: flex;
  flex-direction: row;
  position: relative;
  height: 30px;
}
.inputCurrency{
  width: 100%;
  height: 100%;
  /* padding-top: 10px; */
  border: none;
  background-color: transparent;
  color: #19012e;
  text-align: right;
  padding-right: 34px;
}
.nftView{
  width: 70% !important;
}
.incrementer{
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  right: 0;
}
.incrementerIcon{
  height: 13px;
  width: 30px;
  border-radius: 4px;
  border: 2px solid rgb(105, 63, 204);
  padding-top: 0;
  position: relative;
}
.testd{
  position: absolute;
  top: 0;
  left: 25%;
  padding-bottom: 4%;
}
.currencySelect{
  background-color: rgb(105, 63, 204);
  width: 30%;
  height: 26px;
  border-radius: 4px;
  padding-left: 0;
  color: white;
  font-weight: bold;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.filePreview{
  display: flex;
  flex-direction: row;
  padding: 5%;
  width: 32vw;
  height: auto;
  background-color:#F6F6F6;
  border-radius: 12px;
  margin-bottom: 5%;
}
.nftPreview{
  margin-left: 10%;
}
.bottomFeedback{
  position: fixed;
  bottom: 0%;
  width: 100vw;
  height: 50px;
  background-color: rgb(105, 63, 204);
  color: white;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 320px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  
}

.meltButton{
  background: linear-gradient(180deg , rgb(253, 182, 29) 14%, rgba(240, 69, 252, 0.521) 100%);
  cursor: pointer;
  margin-left: 40%;
  width: 200px;
  text-align: center;
  border-radius: 99px;
  padding-top: 0.4%;
  /* width: 130%; */
}
.brcmbOther{
  /* margin-left: 330px !important; */
}
.transferButton{
  background: linear-gradient(180deg , rgba(240, 69, 252, 0.521) 14%, rgba(139, 69, 252, 0.521) 100%);
  margin-left: 5%;
  width: 200px;
  text-align: center;
  border-radius: 99px;
  padding-top: 0.4%;
}
.selectedDescription{
  margin-top: 5%;
  color: #19012E !important;
}
.openItem{
  display: flex;
  flex-direction: row;
  /* margin-left: 300px; */
  /* color: white; */
  margin-top: 3%;
}
.selectedFile{
  margin-top: 5%;
}
.opemItemImageViewCont{
  /* display: flex;
  padding-top: 5%;
  justify-content: flex-start;
  flex-direction: column; */
  font-weight: 900;
  background-color: #F6F6F6;
  font-size: 14px !important;
  width: 288px;
  margin-right: 10px;
  /* margin-right: 20px; */
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.opemItemImageViewCont > div > span {
  font-size: 33px !important;
  font-weight: 600 !important;
  display: flex;
  padding-top: 5%;
  justify-content: flex-start;
  flex-direction: column;
}
.opemItemImageViewCont > div {
  padding: 4px;
  color: #260082 !important;
  font-weight: bold;

}
.opemItemImageView{
  height: auto;
  width: 100%;
  /* border: 1.5px solid rgb(105, 63, 204); */
  /* border-radius: 12px */
}
.subHead{
  font-size: 14px;
  color: black;
  font-weight: bold;
}
.subHeadDesc{
  font-size: 12px;
  font-weight: bold;
}
.itemName{
  color: #5200E5;
  font-weight: bold;
  font-size: 20px;
}
.itemDescription{
  font-size: 12px;
}
.openItemImageHeader{
  text-align: left;
  font-weight: bold;
  margin-top: 5%;
  margin-bottom: 5%;
  color: #5200E5;
}
.itemBonuses{
  font-size: 12px;
  color: #5200E5;
  display: flex;
  flex-direction: row;
}
.openItemProperties{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;
  font-size: 1.1vw;
}
.openImageDesc{
  width: 30vw;
  /* color: white; */
  text-align: left;
  font-weight: bold;
  margin-left: 5%;
}
.nam{
  font-size: 28px;
  font-weight: bolder;
  margin-top: 5%;
  margin-bottom: 5%;
}
.ksat{
  color: rgb(255, 217, 0);
  font-size: 15px;
  font-weight: bolder;
  padding-top: 5%;
}
.ides{
  margin-top: 5%;
  font-weight: bold;
  color: grey;
  margin-bottom: 10%;
}
.commentBox{
  position:  relative;
  height: 100px;
  background: rgba(241, 239, 238, 0.411);
  border-radius: 12px;
  margin-top: 5%;
  font-size: 6px;
  padding: 5%;
  
  color: rgba(189, 188, 188, 0.589);
}
p{
  opacity: 0.8 !important;
  font-size: 9px;
  line-height: 15px !important;
}
.avtrCustom{
  width: 40% !important;
}
.commentBoxComingSoon{
  position: absolute;
  top: 40%;
  left: 30%;
  z-index: 10;
  font-size: 1.5vw;
  color: grey;
  opacity: unset !important;
}
.transferButton2{
  background: linear-gradient(180deg , rgba(240, 69, 252, 0.521) 14%, rgba(139, 69, 252, 0.521) 100%);
  margin-left: 5%;
  width: 250px;
  text-align: center;
  border-radius: 4px;
  padding-top: 3%;
  height: 40px;
  font-weight: bolder;
  cursor: pointer;
}
.sellButton{
  background: linear-gradient(180deg , rgba(69, 81, 252, 0.521) 14%, rgba(69, 148, 252, 0.521) 100%);
  margin-left: 5%;
  width: 250px;
  text-align: center;
  border-radius: 4px;
  padding-top: 3%;
  height: 40px;
  font-weight: bolder;
  margin-top: 5%;
  cursor: pointer;
}
.marketPlaceHead{
  text-align: left;
  font-size: 30px;
  font-weight: 900;
  width: 300px;
  line-height: 29px;
  color: #19012E !important;

}
.breadcrumbs{
  text-align: left;
  color: grey;
  font-weight: bolder;
  text-transform: capitalize;
  
}
.marketplaceHeader{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid grey;
  color: grey;
  margin-right: 10%;
  padding: 2% 2%;
  justify-content: space-between
}
.chevronBack{
  width: 10px;
  margin-right: 2%;
}
.but{
  color: white;
  font-size: 15px !important;
  font-weight: 900 !important;
  text-transform: uppercase;
  background: #5200E5;
  width: 188px;
  text-align: center;
  border-radius: 99px;
  padding-top: 9px;
  height: 35px;
  font-weight: bolder;
  line-height: 15px;
  margin-top: 20px;
}
.videoBg{
  background-color: rgba(0, 0, 0, 0.82) !important;

}
.bg{
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.603);
  z-index: 2
}
.customModal{
  /* z-index: 3; */
  position: fixed;
  background-color: #FFF;
  border-radius: 12px;

}
.customItemHeader{
  margin-left: 270px;
}
.progressModal{
  position: fixed;
  /* padding: 10px; */
  color: black;
  width: 30vw;
  height: 300px;  
  padding: 1% 3%;
  /* margin-left: 30vw; */
  background-color: white;
  /* margin-top: 10%; */
  border-radius: 12px;
}
.progressTitle{
  text-transform: uppercase;
  font-weight: bold;
  color: #140A48;
}
.progressStringContent{
  text-align: center;
  /* border: 1px solid red; */
  margin-top: 2%;
  height: 50%;
  
}
.popupLogoFyx{
  width: 100px;
  height: auto
}
.promoText{
  font-weight: bold;
  font-size: 12px;
  color: grey
}
.activeMethod{
  color: #140A48 !important;
}
.methodDesc{
  font-size: 12px;
  color: grey;
  font-weight: bold;
  text-align: center
}
.modalRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.modalBut{
  margin: unset;
  padding: unset;
  height: 40px;
  color: whitesmoke;
  /* width: unset !important; */
  flex: 1;
  
  
}
.modalRow1{
  padding: 10px;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #19012E;
  font-weight: 900;

}
.modalRow2{
  padding: 20px;
}
.modalRow3{
  padding: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: space-between;

}
.modalButC{
  margin-left: 10px;
}
.modalCancel{
  position: absolute;
  right: 10px

}
.modalCancelWallet{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 200;
  
  /* top: 10; */
  /* margin-left: 90%; */

}
.inputHandle{
  border: 1px solid grey;
  background: transparent;
  border-radius: 2px;
  text-align: center;
}
.collTab{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.createColl{
  color: #19012E;
  font-size: 3vh;
  font-weight: 900;
}
.collectionList{
  margin-top: 30%;
}
.collectionBox{
  height: 100px;
  /* width: 200px; */
  width: 100%;
  border: 1px solid grey;
  margin-bottom: 5%;
  padding-top: 5%;
  font-size: 10px;
  border-radius: 12px;
  /* display: flex;
  flex-direction: row;
  justify-content: space-evenly; */
}
.walletTest{
  width: 100vw;
  height: 120%;
  background-color: white;
  
  margin-top: -5%;
  padding-top: 5%;
}
.collName{
  color: white
}
.cusBut{
  margin-left: 0%;
  margin-top: 12%;
}
@keyframes tran {
  from {left: -700px;}
  to {left: 0px;}
}
.successFeedback{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: rgb(40, 139, 40);
  animation-name: tran;
  animation-duration: 2s;
  padding-top: 1%;
  padding-right: 10%;
  color: white;
  font-weight: bold;
  text-align: right;
  z-index: 5
}
.collBanner{
  display: flex;
  color: #fff;
  font-weight: 700;
  font-size: 0px;
  flex-direction: row-reverse;
  /* justify-content: flex-end; */
  overflow-x: scroll !important;
  
  /* flex-wrap: wrap; */
  /* background-color: yellowgreen; */
  /* height: 50px; */
  /* -ms-overflow-style: none;  
  scrollbar-width: none;   */
}
.collBanner::-webkit-scrollbar {
  display: none;
}
.collImage {
  min-width: 50px;
  min-height: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.collBlock{
  opacity: 0.4;
  width: 40px;
  height: 40px;
  /* height: 100%; */
  /* background-color: violet; */
  padding: 0;
  border: 1.5px solid #4F42C0;
  border-radius: 20px !important;
  margin-left: 5px;
  background-size: contain !important;
}

.collLabel {
  text-align: 'center';
  width: '100%';
}
.repurposed{
  display: none !important;
}
.walletSubbar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.walletSubbarIconCont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-around;
  align-items: center;
  min-width: 25%;
  max-width: 25%;
  cursor: pointer;
}
.walletSubbarText{
  margin-top: 15%;
  width: 90%;
  height: 30%;
  font-size: 80%;
  font-weight: 900;
  letter-spacing: 0.01em;
  line-height: 1em;
  flex-wrap: wrap;
}
.walletSubbarIcon{
  width: 40px;
  height: 40px;
}
.walletContFooters{
  
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  margin-top: 2%;
  text-align: left !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.copyPopup{
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.603);
  z-index: 2;

}
.copyPopupContent{
    width: 400px;
    /* height: 10; */
    background-color: white;
    align-self: center;
    border-radius: 8px;
    padding: 25px;
    position: relative;
}
.copyContent{
  font-weight: 800;
  margin-bottom: 5%;
}
.copyClose{
  position: absolute;
  top: 10px;
  right: 10px;
}
.topupCurrSelector{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  color: grey;
  margin: 0% 0%;
  padding: 0% 0%;
}
.topupCurrSelection{
  border-bottom: 2px solid #5200E5;
  color: #5200E5;
}
.methodHeading{
  color: black;
}
.weaveHeader{
  margin-top: 5%;
  font-size: 20px;
  color: #5200E5;
  font-weight: bold;
  text-align: left;
  margin-left: 10%;
}
.weaveTransactionStatus{
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  text-align: left;
  margin-left: 10%;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
}
.weaveTransactionStatus span{
  color: #5200E5;
  font-size: 16px;
  font-weight: 600;
}
.amountDisplay{
  display: flex;
  flex-direction: row;
  color: black;
  font-size: 11px;
  font-weight: 600;
  margin-top: 1%;
}
.calculateButton{
  margin-left: 3%;
  color: #5200E5;
  border: 1px solid #5200E5;
  padding: 0% 2%;
  border-radius: 10px;
  cursor: pointer;
}
.iVC{
  width: 75% !important
}
.walletTxButton{
  width: 100%;
  color: white;
  background-color: #5200E5;
  font-size: 16px;
  height: 34px;
  border-radius: 20px;
  margin-top: 4%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}
.walletTxButtonDisabled{
  opacity: 0.8;
  background-color: #D3E3C9 !important;
  color: rgba(0, 0, 0, 0.623) !important;
}
.metamaskDivider{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.metamaskBox{
  height: 40px;
  width: 60px;
  padding: 0% 1%;
  border-radius: 3px;
  margin-bottom: 3%;
  border: 1px solid rgba(128, 123, 123, 0.445);
}
.withdrawText{
  font-size: 11px;
  
}
.customWalletCont{
  min-width: 25vw;
  max-width: 35vw;
}
.walletInputContainers{
  /* border: 1px solid rgb(196, 191, 191) !important; */
  background-color: rgba(197, 194, 194, 0.514) !important;
  border-radius: 3px;
  color: black;
  font-size: 14px !important;
  width: 100% !important;
  height: 40px !important;
}
.widthdrawSteps{
  display: flex;
  text-align: left;
  flex-direction: row;
  width: 100%;
  padding-right: 5%;
  justify-content: space-between;
}
.disclaimerText{
  font-weight: 700;
  font-size: 11px;
}
.depositVendorsCont{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /* background-color: brown; */
  width: 100%;
  height: 300px;
}
.depositVendor{
  background-color: white;
  width: 30%;
  height: 30%;
  border-radius: 8px;
  border: 1px solid rgb(209, 206, 206);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.vendorFooters{
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    margin-top: 2%;
    text-align: center !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}
.vendorIcon{
  width: 80%;
  height: auto;
}
.popupBalance{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.vIconInside{
  width: 70px !important;
  height: auto !important;
  margin: unset !important;
}
.actionSwitch{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(209, 207, 207);
  /* padding: 2%; */
}
.actionSwitchTab{
  margin-right: 5%;
  padding-bottom: 2%;
  width: 100px;

}
.activeActionTab{
  font-weight: bold;
  border-bottom: 3px solid #5200E5;
}
.actionTabCont{
  /* border: 1px solid red; */
  padding: 5%;
}
.walletActionInput{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  background-color: rgba(197, 194, 194, 0.514);
  border-radius: 4px;
}
.walletCurrencyInputCont{
  text-align: left;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 1%;
  
}
.walletCurrencyInputContCustom{
  width: 70% !important;
}

.walletCurrencyInputCont input:focus, .walletCurrencyInputCont textarea:focus, .walletCurrencyInputCont select:focus{
    outline: none;
    background: none !important;
}

.walletCurrencyInput{
  border: none;
  font-size: 16px;
  min-width: 100%;
  background-color: initial;
}
.walletInputDD{
  border-left: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
.exchangeDD{
  width: 120%;
  font-size: 10px;
  /* background: red !important; */
}
.exchangeDD div{
  background: rgb(224, 223, 224) !important;
  border: unset;
  color: #5200E5;

}
.css-26l3qy-menu{
  background: white !important;
}
.addressesInputCont{
  height: 60px !important;

  
}
.addressesInput{
  width: 120% !important;
  height: 80px !important;
  font-size: 10px !important;

}
.transferIndicator{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2% 20%;
  align-items: center;
}
.loaderscreen{
  background-color: rgba(51, 49, 50, 0.932);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
}
.sortIndicator{
  color: #5200E5;
  font-weight: bold;
}
.sortValue{
  color:rgba(100, 97, 97, 0.527);
  
}
.collectionBandCont{
  /* background-color: red; */
  width: 80%;
  text-align: left;
  font-size: 10px;
  font-weight: bold;
  color: black;
  margin-top: 2%;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important; 
}
.collectionBand{
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 2%;
  position: relative;

}
.hoverItem{
  display: none;
  position: absolute;
  border: 1px solid #483964;
  border-radius: 8px;
  width: 380px;
  height: 280px;
  top: -180px;
  /* left: -300px; */
  z-index: 22;
  font-size: 11px !important;
  padding: 5% !important;
  background-color: white;
  opacity: 0.95;
  overflow: hidden
}
.hoverItem .itemBonuses{
  font-size: 10px !important;
}

.test{
  width: 100%;
  height: 20%;
  background-color: unset;
  font-size: 11px;
  margin-top: 1%;
  position: relative;
  overflow-wrap: break-word;
  word-wrap: break-all;
  hyphens: auto;
  
}
.text {
  position: absolute;
  height: 200px;
  width: 150px;
  flex-wrap: wrap;
  overflow: wrap;
  
  background: lightblue;
}
.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  transform: rotate(90deg);
  border-color: transparent #5200E5 transparent transparent;
  right: 0px;
  bottom: -10px;
  /* overflow: hidden */
}
.itemBox:hover .hoverItem {
  display: block;
}
.collectionBandCont::-webkit-scrollbar {
  display: none !important;
}
.parentColl .unselectedCollText {
  display: none;
}
.parentColl:hover .unselectedCollText {
  display: block;
}
.unselectedCollText{
  position: absolute;
  color:rgba(0, 0, 0, 0.932);
  text-align: center !important;
  /* padding-left: 5%; */
  text-align: center;
  font-size: 7px;
  min-width: 50px;
  /* width: 80%; */
  padding-top: 5px;
  line-height: 8px !important;
  
}
.selectedCollText{
  position: absolute;
  color:rgba(0, 0, 0, 0.932);
  text-align: center !important;
  /* padding-left: 5%; */
  font-size: 7px;
  padding-top: 5px;
  /* width: 80%; */
  line-height: 8px !important;
  
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 1140px){
  .avtr-det{
    width: 11vw !important;
  }
  .customWalletCont{
    min-width: none;
    max-width: none;
  }
  .player-details{
    padding-right: 10%;
  }
  .fighterStats{
    margin-left: 0%;
  }
  .nav-top{
    width: 260px !important;
  }
  .fighterCont{
    margin-left: 270px !important;
  }
  .bottomFeedback{
    height: 70px;
  }
  .feedbackButton1{
    right: 20% !important
  }
  .feedbackButton2{
    right: 10% !important
  }
  .depositContainer{
    margin-top: 10%;
  }
  .repurposed{
    display: none !important;
  }

}

@media only screen and (max-width: 1140px){
  .hoverItem{
    left: 0;
  }
  .small-font-copy{
    font-size: 8px !important;
    height: 50px;
    word-wrap: break-word !important;
  }
  .iVC{
    width: unset !important;
  }
  .openItemImage{
    width: 40vw;
  }
  .nftView{
    width: unset !important;
  }
  .highlightContainer{
    flex-wrap: wrap !important;
    margin-top: 5% !important;
    margin-bottom: 15% !important;
    align-content: center !important;
    justify-content: center !important;
  }
  .highlightHeading{
    padding-left: 5%;
  }
  .searchCont{
    flex-direction: column;
  }
  .searchBar{
    margin-left: 20%;
  }
  .transactionsTable{
    font-size: 7px !important;
    margin-left: 3%;
  }
  .verticalLine{
    display: none;
  }
  .marketplaceName{
    font-size: 18px !important;
  }
  .tableElement1{
    width: 15% !important;
  }
  .verticalLine{
    margin-left: 25.5% !important;
  }
  .giCont{
    padding-left: unset !important;
  }
  .seedButton{
    width: 70px !important;
    font-size: 6px !important;
  }
  .skills{
    font-size: 10px !important
  }
  .skillList{
    font-size: 10px !important
  }
  .itemDividerMob{
    font-style: 16px !important;
  }
  .fighterSpan{
    font-size: 12px !important;
  }
  .gameInfoBox1{
    width: 90% !important;
    padding-left: 2%;
    padding-right: 2%;
  }
  .marketplaceHeader{
    flex-direction: column;
    /* height: 20vh; */
    align-items: center;
    padding: 5% 2% !important;
    width: 96vw !important;
  }
  .itemDescMob{
    width: 85vw !important;
  }
  .butY{
    margin-right: unset;
    margin-bottom: 2%;
  }
  .openItem{
    width: 96vw !important;
  }
  .collectionBand{
    width: 98vw;
    overflow: scroll;
  }
  .paginationCont{
    width: 100% !important;
  }
  .itemBox{
    height: unset !important;
  }
  .walletHeader{
    height: 15vh !important;
    font-size: 20px !important
  }
  .itemView{
    margin-left: 20px !important;
  }
  .brcmbOther{
    margin-left: 30px !important;
  }
  .customDeposit{
    padding-left: 5%;
    padding-right: 5%;
  }
  .walletIcon{
    font-size: 20vw;
    margin-left: 38%;
  }
  .depositSelector{
    margin-left: 10%
  }
  .additionalFilesCont{
    margin-left: -10% !important;
  }
  .walletTab{
    flex-direction:column ;
  }
  .comingSoon::after {
    font-size: 6.5vw !important;
  }
  .attachProp{
    min-width: 30vw !important;
    
  }
  .walletHeadingCont2{
    min-width: unset !important;
    margin-left: unset !important
  }
  .qrCode{
    flex-direction: column;
  }
  .fileLabel{
    font-size: 3vw;
    border: 1px solid grey;
  }
  .additionalFilesObj{
    width: 42vw !important
  }
  .deposit{
    width: 90vw
  }
  .walletTest{
    margin-top: 0%;
    margin-bottom: -10%;
    padding-bottom: 5%;
  }
  .depositOverlay{
    margin-bottom: 5%;
    margin-left: unset !important;
  }
  .feedbackButton{
    font-size: 12px 
  }
  .feedbackButton1{
    right: 25% !important
  }
  .deposit2{
    width: 100%;
  }
  .fundCollector
  .walletText{
    margin-top: 15%;
  }
  .comingSoon{
    margin-left: 0% !important;
  }
  .infoInactive{
    font-size: 3vw;
    font-weight: unset;
  }
  .nav-top{
    z-index: 20;
    padding-bottom: 35%;
  }
  .gameInfo2{
    width: 95vw;
  }
  .gameInfoHead{
    margin-top: 5%;
    margin-left: 2%;
    width: 90% !important;
    
  }
  .gameInfoBox2{
    margin-top: 2%;
    background: #F6F6F6;
    border-radius: 22px;
  }
  .gameDets{
    flex-direction: column;
  }
  .gameInfo{
    width: 100vw;
  }
  .comingSoon::after{
    transform: translateX(-84px);
    transform: translateY(-50px);
  }
  .nav-resp{
    overflow-y: hidden;
    
  }
  .fighterHp{
    width: 50vw !important;
  }
  .handleDetails{
    
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .itemSection{
    width: 90vw !important;
  }
  .performanceSection{
    width: 90vw !important;
  }
  .App{
    flex-direction: column;
  }
  .started{
    margin-left: 2%;
  }
  .login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-left: 10px;
    /* width: 500px; */
    color: white;
    width: 100vw;
    height: 100vh;
    /* border: 1px solid red; */
    background: url(./assets/login-background.png);
  }
  .login-heading{
    margin-bottom: 10%;
  }
  .navbar-mob{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10%;
    height: 7vh;
    
    /* padding-right: 5%; */
    /* padding-top: 5%;
    padding-bottom: 5%; */
    background-image: linear-gradient(
      180deg
      , #18002d, #111e41);

  }
  .fyx-logo-mob{
    margin-top: 10%;
    margin-bottom: 5%;
    width: auto;
    height: 5vh;
  }
  .nav-top{
    display: none
  }
  .my__carousel_main{
    padding-left: unset !important;
    width: 100vw !important;
  }
  .explorerTableHeaders{
    width: 95vw !important;
    margin-left: 10px !important;
    font-size: 3vw !important;
  }
  .tableHeaders{
    font-size: 3vw !important;
    width: 95vw !important;
  }
  .score{
    /* margin-bottom: 10%; */
    margin-left: 2%;
  }
  .box-text1{
    font-size: 0.7em !important;
    direction: ltr;
  }
  .tf{
    width: 19% !important;
  }
  .tw{
    width: 15% !important;
  }
  .f{
    margin-right: 3px !important;
    width: 9% !important;
  }
  .tableHeaders{
    /* width: 92vw !important; */
    margin-left: 2vw;
  }
  .loginSubCont{
    width: 100% !important;
  }
  .loginSemi{
    width: 88% !important;
  }
  .loginSemiImg{
    display: none;
  }

  .mob-img{
    width: 4vh !important;
    height: auto !important;
  }
  .summary-cont{
    padding-left: unset;
  }
  .timeline{
    width: 60%;
  }
  .participants{
    width: 20%;
  }
  .avtr-det{
    width: 15vw !important;
  }
  .avtr{
    width: 12vw;
    border-radius: 6vw;
  }
  .fight-details{
    /* width: 39vw; */
  }
  .repurposed{
    display: block !important;
    width: 90vw !important;
    margin-left: 5vw;
  }
  .timelineVideo{
    display: none;
  }
  .action-box-headline{
    font-size: 10px !important;
    padding-left: 3% !important;
  }
  .timestamp{
    font-size: 1.1vh !important;
    padding-left: 3% !important;
  }
  .small{
    width: 6vw !important;
    height: 6vw !important;
    border-radius: 3vw !important ;
  }
  .fighterCont{
    margin-left: 5% !important;
  }
  .custom-mob{
    width: 90vw !important;
    font-size: 2.5vw !important;
  }
  .custom-mob-score{
    font-size: 3.2em !important ;
  }
  .marketplaceItemContainer{
    margin-top: 5% !important;
  }
  .itemTypeBanner{
    width: 90vw !important;
    margin-left: 5% !important;
  }
  .modal-content{
    margin-left: 5% !important;
    width: 80vw !important;
    font-size: 14px !important;
  }
  .modalText{
    padding-left: 3%;
  }
  .itemImageFighter{
    height: 12vw !important;
    width: auto !important;
    margin-bottom: 20%;
    
  }
  .customIb{
    height: 25vw !important;
  }
  .progressModal{
    width: 75vw;
  }
  .itemViewModal{
    /* margin-top: 5% !important; */
    min-width: 90vw !important;
    max-width: 90vw !important;
    height: 90vh !important;
    /* margin-left: -20% !important; */
    padding-bottom: 10%;
    margin-top: 5% !important;
  }
  .loaderSpinner{
    margin-left: 0% !important;
  }
  .bannerLeaderboard{
    width: 90vw !important;
    margin-left: unset !important;
  }
  .actionSwitchTab{
    font-size: 12px !important;
  }
  .walletSubbarText{
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 680px){
  .itemContainer{
    padding-left: 10%;
  }
  .itemBox:hover .hoverItem {
    display: none;
  }
}
@media only screen and (max-width: 500px){
  .menu-drawer{
    display: none !important
  }
  .mobile-menu{
    display: flex !important
  }
  .filters{
    display: none !important;
  }
  .repurposed{
    display: none !important;
  }
  .customtable{
    width: 100% !important;
  }
  .metamaskDivider{
    display: none
  }
  .box-text{
    font-size: 0.8em !important;
  }
  .commentBoxComingSoon{
    font-size: 4vw;
  }
  .timelineVideo{
    display: block;
    width: 90vw;
    margin-left: 5vw;
    margin-bottom: 5%;
    order: -1;
  }
  .currencySelectDD{
    width: 15vw;
  }
  .feedbackButton1{
    right: 30% !important;
  }
  .walletHeading{
    font-size: 6vw;
  }
  .balanceContWallet{
    font-size: 5vw;
  }

  .bottomFeedback{
    height: 100px;
    padding-left: 5px;
  }
  .openItem{
    margin-left: 5%;
    flex-direction: column;
  }
  .openItemImage{
    width: 90vw;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .openItemProperties{
    font-size: 3vw;
  }
  .openImageDesc{
    width: 85vw;
  }
  .meltButton{
    margin-left: 5%;
    height: 50px !important;
  }
  .transferButton{
    margin-left: 5%;
    height: 50px !important;
  }
  .currencySelector{
    width: 90vw !important;
    flex-direction: column;
  }
  .myItems{
    font-size: 5vw;
  }
  .allItems{
    font-size: 5vw;
  }
  .walletText{
    font-size: 5vw;
  }
  .sel{
    padding: 0 10px;
  }
  .nftContainer{
    flex-direction: column;
  }
  .nftForm{
    width: 80vw !important;
    
  }
  .nftPreview{
    margin-left: -15%;
    font-size: 4vw;
    margin-top: 10%;
  }
  .nftHeaders{
    margin-right: 10%
  }
  .customModal{
    width: 90vw;
    margin-top: 40%;
  }
  .itemTypeBanner{
    margin-left: unset !important;
    display: none;
  }
  .depositContainer{
    flex-direction: column !important
  }
  .collectionBandCont{
    width: 100%;
  }
  .opemItemImageViewCont{
    width: unset !important
  }
  .deposit{
     width: 80vw !important;
  }
  .balanceCont{
    /* margin-left: unset !important; */
    margin-top: 5%;
  }
  .viaPaymail{
    font-size: 3vw;
  }
  .withdraw{
    padding: 15% 5% !important;
  }
  .withdrawButton{
    font-size: 3vw;
  }
  .depositAmountInput{
    font-size: 3vw;
  }
  .helpButton{
    font-size: 3vw;
  }
  .comingSoon{
    margin-left: 0px ;
    width: 100vw;
    height: 100vh;

  }
  .marketPlaceHead{
    font-size: 2.9vw !important;
    width: 35vw;

  }
  .ivbT{
    margin-left: 5% !important;
  }
  .nav-top{
    width: 100vw !important;
  }
  .leaderBoardTable{
    width: 96vw;
    margin-left: 2vw;
    font-size: 12px;
  }
  .summary-cont{
    flex-direction: column;
    
  }
  .fighterHp{
    width: 70vw !important;
  }
  .itemSection{
    width: 70vw !important;
    margin-left: 0% !important;
  }
  .itemH{
    width: 100vw !important;
    font-weight: 600;
  }
  .fighterStats{
    margin-left: 8%
  }

  .performanceSection{
    width: 100vw !important;
    margin-left: 0% !important;

  }
  .itemViewBox{
    padding-left: 8% !important;
    

  }
  .customIb{
    height: 40vw !important;
  }
  .customIb2{
    width: 10vw !important;
    padding-left: 5%;
  }
  .timeline{
    width: 95vw !important;
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }
  .action-box{
    width: 100% !important;
  }
  .action-box-headline{
    font-size: 10px !important;
    line-height: 180% !important;

  }
  .timestamp{
    font-size: 3vw !important;
  }
  .started{
    margin-left: unset !important;
    width: 74% !important;
  }
  .participants{
    width: 100vw !important;
    padding-left: 5% !important;
    padding-bottom: 10% !important;
  }
  .avtr-det{
    width: 40vw !important;
    justify-content: space-between;
    margin-right: 20px !important;
  }
  .fighterDetCont{
    width: 34vw !important;
    height: 50vw !important;
  }

  .name-custom{
    font-size: 15px !important;
  }
  .fighterImgCont{
    width: 20vw !important;
    height: 30vw !important;
  }
  .fighterStats{
    font-size: 0.7em;
  }
  .fighterSpan{
    min-width: 40px;
  }
  .fighterCont{
    margin-left: 2% !important;
  }
  .avtr{
    
    width: 30vw !important;
    height: 30vw !important;
    border-radius: 15vw !important;
  }
  .avtrCustom{
    width: 15vw !important;
    height: 15vw !important;
  }
  .small{
    /* display: none */
    height: 10vw !important;
    width: 10vw !important;
    border-radius: 2vw;
  }
  .fight-details{
    width: 85% !important;
    font-size: 4vw;
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }
  .fighter-name{
    font-size: 2vw;
  }
  .player-details{
    margin-bottom: 10% !important;
  }
  .custom-mob-break{
    flex-direction: row !important;
    /* background-color: green; */
  }
  .skillList{
    padding-left: 2% !important;
    padding-right: 2% !important;
  }
  .mob-skilllist{
    margin-bottom: 2% !important;
    max-width: 70px !important;
    /* margin-left: 40%; */
  }
  .itemTypeBanner{
    width: 90vw !important;
    font-size: 9px !important;
    /* margin-left: -5% !important; */
  }
  .itemDivider{
    margin-left: 10%;
  }
  .itemContainer{
    padding-left: 5% !important;
    padding-right: 0%;
  }
  .divCus{
    margin-left: 20%;
    margin-top: 5%;
  }
  .itemView{
    margin-left: 8% !important;
    margin-left: 2% !important;
  }
  .itemBox{
    width: 42vw !important;
    height: 100% !important;
    
  }
  .itemImage{
    width: 100%!important;
    height: auto !important
  }
  .itemImageMob{
    width: 30vw !important;
    height: auto !important;
    padding-top: 20%;
  }
  .myItems{
    padding-top: 2%;
  }
  .allItems{
    padding-top: 2%;
  }
  .modal-content{
    margin-left: 2% !important;
    width: 86vw !important;
    font-size: 10px !important;
  }
  .modalText{
    padding-left: 3%;
  }
  .itemDividerMob{
    font-size: 16px !important;
    font-weight: 900;
    width: 45vw !important;
    margin-bottom: 5%;
  }
  .mobCreateButton{
    margin-left: 20% !important
  }
  .tableHeaders{
    border-radius: 12px !important;
  }
  .liveDot{
    display: none
  }
  .hoverItem{
    display:none
  }
  .walletHeader{
    margin-left: 0% !important;
    width: 100vw !important;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .tableHeadersVal{
    border-radius: 12px !important;
  }
  .itemBoxFighter{
    height: 30vw !important;
    min-width: 15vw !important;
    margin-top: 5% !important;
    text-align: center;
    font-size: 2.5vw !important;
  }
  .itemImageFighter{
    height: 16vw !important;
    width: auto !important;
    margin-bottom: 20%;
    
  }
  .cusBut{
    font-size: 3vw;
  }
  .collectionList{
    margin-top: 10%;
  }

  .tfMob{
    width: 12% !important;
  }
  .filePreview{
    margin-left: 18%;
    width: 80vw;
  }
  .twMob{
    width: 20% !important;
  }
  .fMob{
    width: 20% !important;
  }
  .comingSoon::after {

    transform: translate(-64px, -50px);

  }

}

button.nft-upload {
  background: rgb(40 40 103 / 0%);;
  width: 200px;
  height: 200px;
  position: relative;
  border: none;
  cursor: pointer;
}

.plus::before, .plus::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-color: #5200e5;
    border-style: solid;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.plus.bottom-corners::before {
  border-width: 0 0 1px 1px;
  left: 25px;
  bottom: 25px;
}
.plus.bottom-corners::after {
  border-width: 0 1px 1px 0;
  right: 25px;
  bottom: 25px;
}
.plus.top-corners::before {
  border-width: 1px 0 0 1px;
  left: 25px;
  top: 25px;
}
.plus.top-corners::after {
  border-width: 1px 1px 0 0;
  right: 25px;
  top: 25px;
}
button:hover .plus.top-corners::before{
  top: 100px;
  left: 100px;
}
button:hover .plus.top-corners::after{
  top: 100px;
  right: 100px;
}
button:hover .plus.bottom-corners::before{
  bottom: 100px;
  left: 100px;
}
button:hover .plus.bottom-corners::after{
  bottom: 100px;
  right: 100px;
}
button:hover
span {
  opacity: 0;
}
.spin {
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

button:hover
.spin {
  transform: rotate(90deg);
}

input{
  border: none;
}
textarea{
  border: none;
}
input[type=text]:focus, input[type=password]:focus, textarea:focus, input[type=number]:focus {
  background: #b5b6de75;
  color: #19012e;
}
.input-error {
  border: 2px solid #e03997;
  background: #f7dcdc;
  color: #920153;
}
.QuickLabel {
  text-transform: uppercase;
  color: #68626C;
}
.walletSemi {
  width: 100% !important;
  margin: 0 auto !important;
}
.Amount {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 16px;
  color: #140A48;
  margin: 15px;
}
.csWallet{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  padding: unset !important;
  /* width: 50vw !important; */
  /* min-width: 40vw !important;
    min-height: 300px; */
}
.centerText{
  position: absolute;
  
}
.helpArticles{
  text-align: left;
  font-weight: 700;
  margin-top: 2%;
}
.getHelpCont{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}
.loaderSpinner{
  margin-left: 50%;
}
.helpArticleCont{
  width: 50%;
  padding: 2%;
  text-align: left;
  font-size: 9px;
  cursor: pointer;
  
}
.articleHeading{
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 17px;
  color: #4D00D5;
  opacity: 0.63;
}
.articleDesc{
  font-style: normal;
  font-weight: bold;
  line-height: 12px;
  color: #897E93;
  opacity: 0.63;
  margin-left: 1%
}

.iconHelp{
  width: 14px;
  height: auto
}